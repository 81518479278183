import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
   Paper,
  TextField,
} from "@mui/material";
 

function AutoCompleteSelect(props) {
  const { options, disabled, name, value, placeHolder, error, handleSelect } =
    props;
  return (
    <form
      style={{ display: "contents", color: `#403e3e !important` }}
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      <Autocomplete
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& ul": {
                "& li": {
                  fontFamily: "font_d",
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: "#181818",
                  borderBottom: "1px solid #E0E0E0",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              maxHeight: 200,
              overflow: "hidden hidden",
            }}
          >
            {children}
          </Paper>
        )}
        disabled={disabled}
        value={value}
        name={name}
        options={options || []}
        onChange={(event, values) => handleSelect(event, values, name)}
        fullWidth
        renderInput={(params) => (
          <TextField
            style={{
              border: error ? "1px solid red" : "none",
              paddingTop: "10px",
              fontFamily: "font_c",
            }}
            fullWidth
            name={name}
            error={error}
            variant="outlined"
            placeholder={placeHolder}
            {...params}
            inputProps={{
              ...params.inputProps,
              style: {
                fontFamily: "font_c",
                fontSize: 16,
                lineHeight: "normal",
                letterSpacing: "normal",
                color: "#181818",
              },
              autocomplete: "off",
              "aria-autocomplete": "none",
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              autoComplete: "off",
            }}
          />
        )}
        disablePortal={false} 
      />
    </form>
  );
}
AutoCompleteSelect.defaultProps = {
  label: "",
  required: false,
  disabled: false,
  fullWidth: true,
  value: null,
  name: "",
  classes: {},
  placeHolder: "",
  error: false,
  handleSelect: () => {},
};

AutoCompleteSelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.string,
  classes: PropTypes.object,
  placeHolder: PropTypes.string,
  error: PropTypes.bool,
  handleSelect: PropTypes.func,
};

export default AutoCompleteSelect;
