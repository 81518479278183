import {features} from '../utils/constants';
import {get} from './HttpProvider';

const SERVICE_URLS = {
  tracking: 'v5-shippings/',
  trackingDetails: 'v5-shippings/order-info-contacts'
  // trackingDetails: 'v5-bilties/order-info-contacts'

};

const getTrackingByOrderAndShipping = (url) =>
  get(`${SERVICE_URLS.tracking}${url}`, {}, {feature: features.tracking});

const getTrackingConDetails = (url) =>
  get(`${SERVICE_URLS.trackingDetails}${url}`, {});

export const apiServices = {
  getTrackingByOrderAndShipping,
  getTrackingConDetails
};
export default apiServices;
