import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import PropTypes from 'prop-types';

export function createRoutineMachineLayer(props) {
  const { waypoints } = props;

  const instance = L.Routing.control({
    waypoints: waypoints,
    lineOptions: {
      styles: [
        {
          color: 'green',
          opacity: 0.8,
          weight: 4,
        },
      ],
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: false,
    draggableWaypoints: false,
    fitSelectedRoutes: true,
    showAlternatives: false,
    createMarker: function (i, waypoint, n) {
      return null;
    },
  });
  return instance;
}
const RoutingMachine = createControlComponent(createRoutineMachineLayer)
RoutingMachine.defaultProps = {
  draggable: false,
  showAlternatives: true,
  waypoints: [],
  addWaypoints: false,
  pathColor: {
    color: 'blue',
    opacity: 0.6,
    weight: 4,
  },
};
RoutingMachine.propTypes = {
  draggable: PropTypes.bool,
  showAlternatives: PropTypes.bool,
  addWaypoints: PropTypes.bool,
  waypoints: PropTypes.array,
  pathColor: PropTypes.object,
};
export default RoutingMachine;
