import axios from "axios";

import {getToken} from "../utils/auth.utlis";
  // const BASE_URL = "http://192.168.0.149:4000/api/v2/";
const BASE_URL = "https://server.hitechlogistics.io/api/v2/";
// const BASE_URL = "https://www.hitechlogistics.io/api/v2/"



export async function getApiRequestHeader()
{
  const authtoken = await getToken();
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authtoken ? `Bearer ${authtoken}` : "",
  };
}

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  withCredentials: false,
});

export async function updateHeaders()
{
  const header = await getApiRequestHeader();
  instance.defaults.headers = header;
}

export async function request({method, url, data, headers})
{
  if (headers === undefined)
  {
    await updateHeaders();
  }
  const promise = instance[method](url, data);
  let response;
  try
  {
    response = await promise;
  } catch (error)
  {
    return error.response.data;
  }
  return response.data;
}

export async function get(url, params, featureAndAction, config)
{
  for (const key in params)
  {
    url = url + "/" + params[key];
  }
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  });
}

