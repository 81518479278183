import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import L from "leaflet";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import apiServices from "../services/RequestHandler";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import localStoreUtil from "../utils/localstore.util";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import RoutingMachine from "./RoutingMachine";
import laodingIcon from "../routes/dropoff.png";
import laodingLocationIcon from "../routes/pickup.png";
import Loader from "./Loader";
import truck from "../routes/delivery.png";
import airplane from "../routes/airplane.png";
import ship from "../routes/cruise.png";
import CloseIcon from "@mui/icons-material/Close";
import train from "../routes/train.png";
import _ from "lodash";
import citiesData from "../usableComponent/cities.json";
import countriesData from "../usableComponent/countries.json";
import timezone from "../usableComponent/timezone.json";
import AutoCompleteSelect from "../usableComponent/AutoCompleteSelect";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/system";
import "./Style.css";
import "../App.css";
import { TrackInformationPDF } from "./Document/MyDocument";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { current_zone } from "../utils/constants";

export default function OrderDetail(props) {
  const [lastTracking, setLastTracking] = React.useState({});
  const [trackingDetails, setTrackingDetails] = React.useState([]);
  const [isMapinit, setIsMapinit] = React.useState(false);
  const [wayPoints, setWaypoints] = React.useState([]);
  const [allShippings, setAllShipping] = React.useState([]);
  const [ship_id, setShipID] = React.useState(0);

  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cities: null,
      countries: null,
      showDialog: false,
      loader: true,
      screen_render: true,
      region: null,
      time: {},
      data: null,
      open: false,
      user_timezone: null,
      openSupportDialog: false,
      right: false,
    }
  );
  const direction = [24.920402582853196, 67.09712055886521];
  const navigate = useNavigate();
  let _map = React.createRef();
  const params = useParams();
  React.useEffect(() => {
    getOrderByShipping();
  }, [params.id]);

  const handleSelect = (event, value, name, type) => {
    try {
      let obj = {
        ...state.region,
      };
      if (!value) {
        if (name === "user_country") {
          obj["selected_user_country"] = null;
          obj["user_country"] = null;
          obj["selected_user_city"] = null;
          obj["user_city"] = null;
          obj["cities"] = [];
          obj["time"] = null;
        }
        if (name === "user_city") {
          obj["user_city"] = null;
          obj["selected_user_city"] = null;
        }
        if (name === "user_timezone") {
          obj["user_timezone"] = null;
          obj["selected_user_timezone"] = null;
        }
        setState({
          region: obj,
        });
        return;
      }
      if (name === "user_country") {
        const _cities = citiesData?.filter((i) => i.country_id === value.value);
        const _timezone = timezone.filter(
          (i) => i.value === value.capital_tz_id
        );
        setState({
          time: _timezone[0],
        });
        obj["selected_user_country"] = value;
        obj["user_country"] = value.value;
        obj["user_timezone"] = _timezone[0]?.value;
        obj["selected_user_timezone"] = _timezone[0];
        obj["cities"] = _cities;
        obj["user_city"] = null;
      } else if (name === "user_city") {
        const _timezone = timezone.filter((i) => i.value === value.city_tz_id);
        obj[name] = value;
        obj["user_timezone"] = _timezone[0]?.value;
        obj["selected_user_timezone"] = _timezone[0];
        setState({
          time: _timezone[0],
        });
      } else if (name === "user_timezone") {
        obj["user_timezone"] = value.value;
        obj["selected_user_timezone"] = value;
        setState({
          time: value,
        });
      }
      setState({
        region: obj,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleAboutOpen = () => {
    setState({ open: true });
  };
  const handleSupportOpenDialog = () => {
    setState({ openSupportDialog: true });
  };
  const handleClickOpen = () => {
    setState({ showDialog: true });
  };
  const handleClose = () => {
    setState({ open: false, openSupportDialog: false, showDialog: false });
  };
  const getOrderByShipping = async () => {
    try {
      const response = await apiServices.getTrackingByOrderAndShipping(
        `order-infos/${params.order_id}`
      );
      let _sorted = response?.data?.sort((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
          ? -1
          : 1
      );
      let shipping_ids = [...new Set(_sorted.map((item) => item.resource_id))];
      let all_shippings = [];
      shipping_ids.map((j) => {
        let shippings = [];
        _sorted.map((i) => {
          if (j === i?.resource_id) {
            shippings.push(i);
          }
        });
        all_shippings.push({ id: j, label: j, logs: shippings });
      });

      setLastTracking(all_shippings[0]?.logs[0] || {});

      await localStoreUtil.store_data(
        "contact",
        all_shippings[0]?.logs[0] || {}
      );
      const tz_setting = await localStoreUtil.get_data("UserData");
      setAllShipping(all_shippings);
      let _waypoints = [];
      let loggs = _.uniqBy(all_shippings[0]?.logs, "id");

      _waypoints.push(
        L.latLng(
          all_shippings[0]?.logs[0]?.c_order_loading_add_lat,
          all_shippings[0]?.logs[0]?.c_order_loading_add_lng
        )
      );
      for (let log_idx = 0; log_idx < loggs?.length; log_idx++) {
        const log = loggs[log_idx];
        if (log.tracking_status !== "Expected Start") {
          _waypoints.push(L.latLng(log.latitude, log.longitude));
        }
      }
      _waypoints.push(
        L.latLng(
          all_shippings[0]?.logs[0]?.c_order_unloading_add_lat,
          all_shippings[0]?.logs[0]?.c_order_unloading_add_lng
        )
      );
      setWaypoints(_waypoints);
      setTrackingDetails(loggs || []);
      setShipID(all_shippings[0]?.id);
      setState({ loader: true, data: tz_setting, screen_render: false });
    } catch (error) {
      console.log(error);
      setState({ loader: true, screen_render: false });
    }
  };
  const handleMap = (map) => {
    _map = map;
    setIsMapinit(true);
  };
  const handleChange = async (e) => {
    try {
      setShipID(e.target.value);
      if (e.target.value !== 0) {
        let selected_shipping = allShippings?.filter(
          (i) => i.id === e.target.value
        )[0];

        await localStoreUtil.store_data(
          "contact",
          selected_shipping?.logs[0] || {}
        );
        let _waypoints = [];

        _waypoints.push(
          L.latLng(
            selected_shipping?.logs[0]?.c_order_loading_add_lat,
            selected_shipping?.logs[0]?.c_order_loading_add_lng
          )
        );
        _waypoints.push(
          L.latLng(
            selected_shipping?.logs[0]?.c_order_unloading_add_lat,
            selected_shipping?.logs[0]?.c_order_unloading_add_lng
          )
        );
        setWaypoints(_waypoints);
        // setTrackingDetails(selected_shipping?.logs || []);
        setTrackingDetails(_.uniqBy(selected_shipping?.logs, "id") || []);

        setLastTracking(selected_shipping?.logs[0]);
      } else {
        await localStoreUtil.store_data(
          "contact",
          allShippings[0]?.logs[0] || {}
        );

        let all_shippings_logs = [];
        allShippings.map((i) => {
          i?.logs?.map((a) => all_shippings_logs.push(a));
        });
        let sorted = all_shippings_logs.sort((j, l) =>
          new Date(j.createdAt).getTime() > new Date(l.createdAt).getTime()
            ? -1
            : 1
        );
        setTrackingDetails(sorted || []);
        // setTrackingDetails(_.uniqBy(allShippings[0]?.logs, "id") || []);
        setLastTracking(allShippings[0]?.logs[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    try {
      const userData = {
        userCountry: state.region?.selected_user_country?.label,
        userCity: state?.region?.user_city.label,
        userTZ: state.time.label,
      };
      setState({
        data: userData,
      });
      await localStoreUtil.store_data("UserData", userData);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem style={{ display: "flex", flexDirection: "row-reverse" }}>
          {" "}
          <CloseIcon
            style={{
              color: "#3344b5",
              height: "35px",
              width: "35px",
              cursor: "pointer",
            }}
            onClick={toggleDrawer(anchor, false)}
          />
        </ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem
          onClick={() => {
            navigate(`/track/${lastTracking?.customer_order}`);
          }}
          style={{
            fontSize: "18px",
            fontFamily: "font_d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#666666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <ListItemButton
            style={{ color: "black", display: "flex", flexDirection: "row" }}
          >
            Home
          </ListItemButton>

          <ArrowForwardIosIcon style={{ fontSize: "16px", color: "#3344b5" }} />
        </ListItem>
        <ListItem></ListItem>

        <ListItem
          onClick={() => {
            handleClickOpen();
          }}
          style={{
            fontSize: "18px",
            fontFamily: "font_d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#666666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-clock"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          <ListItemButton style={{ color: "black", display: "flex" }}>
            Time Zone
          </ListItemButton>
          <ArrowForwardIosIcon style={{ fontSize: "16px", color: "#3344b5" }} />
        </ListItem>
        <br />
        <ListItem
          onClick={() => {
            navigate(`/track`);
          }}
          style={{
            fontSize: "18px",
            fontFamily: "font_d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#666666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-package"
          >
            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
          </svg>
          <ListItemButton style={{ color: "black" }}>All Orders</ListItemButton>
          <ArrowForwardIosIcon style={{ fontSize: "16px", color: "#3344b5" }} />
        </ListItem>
        <br />

        <PDFDownloadLink
          style={{ textDecoration: "none" }}
          document={
            <TrackInformationPDF lastTracking={lastTracking} {...props} />
          }
          fileName={`TrackingPage.pdf`}
        >
          <ListItem
            style={{
              fontSize: "18px",
              fontFamily: "font_d",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-printer"
            >
              <polyline points="6 9 6 2 18 2 18 9"></polyline>
              <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
              <rect x="6" y="14" width="12" height="8"></rect>
            </svg>
            <ListItemButton style={{ color: "black" }}>
              <span
                className="text22 aa"
                style={{
                  fontSize: "18px",
                  fontFamily: "font_d",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                {" "}
                Print{" "}
              </span>
            </ListItemButton>
            <ArrowForwardIosIcon
              style={{ fontSize: "16px", color: "#3344b5" }}
            />
          </ListItem>
        </PDFDownloadLink>
        <br />

        <ListItem
          onClick={() => {
            handleSupportOpenDialog();
          }}
          style={{
            fontSize: "18px",
            fontFamily: "font_d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#666666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-message-circle"
          >
            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
          </svg>
          <ListItemButton style={{ color: "black" }}>Support</ListItemButton>
          <ArrowForwardIosIcon style={{ fontSize: "16px", color: "#3344b5" }} />
        </ListItem>
        <br />

        <ListItem
          style={{
            fontSize: "18px",
            fontFamily: "font_d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#666666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-info"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
          <ListItemButton
            onClick={() => {
              handleAboutOpen();
            }}
            style={{ color: "black" }}
          >
            About
          </ListItemButton>
          <ArrowForwardIosIcon style={{ fontSize: "16px", color: "#3344b5" }} />
        </ListItem>
      </List>
    </Box>
  );
  const steps = ["", "2", ""];
  return (
    <div
      style={{
        filter: state?.screen_render && "blur(5px)",
      }}
    >
      <Loader open={state?.screen_render} />

      <div
        style={{
          background: "#fff",
          position: "fixed",
          zIndex: 2,
          width: "100%",
        }}
        className="hide-web"
      >
        {/* For Mobile */}
        <Grid container>
          <Grid item xs={12}>
            <Grid container style={{ background: "#3344b5" }}>
              <Grid
                xs={3}
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                <svg
                  style={{
                    fill: "#ffffff",
                    height: "40px",
                    width: "40px",
                    paddingLeft: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 297.68 176.32"
                >
                  <title>PWA-Logistics</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        className="cls-1"
                        d="M14.68,86,70.81,54l-.67-1.59c-6.57,1.72-13.07,3.9-19.75,5-4.75.79-10,1.18-14.58,0C23.74,54.13,11.92,49.9,0,46.05L.16,44l77.9-20.27-.19-1.36c-9.33,0-18.68.29-28-.13-4.25-.19-9-.83-12.53-2.92C28.16,13.86,19.52,7.49,9.22.51,12.3.23,13.6,0,14.91,0Q87,.65,159.05,1.4c5.65.07,11.49.33,16.89,1.79,14.45,3.88,23.32,20.49,20.76,36.7C194.27,55.27,185.25,65,171.78,71.71c-15.9,8-33,11.14-50.29,13.64-30.78,4.44-61.69,4.93-92.67,2.51C24.7,87.54,20.61,86.79,14.68,86Z"
                      />
                      <path
                        className="cls-1"
                        d="M62.56,176.32c2.1-3.22,3.4-5.59,5-7.68,5.33-6.77,11.39-13.06,16.06-20.25,6.91-10.63,18.59-12.37,28.78-16.71,29-12.34,55.4-28.6,77-51.63C201.15,67.56,211,53.34,210.12,34.8c-.2-4.58-.54-9.41-2.21-13.58s0-6.37,2.77-8.47C230.26-2,252.47-3.35,273.25,8.93c8.86,5.25,16.33,12.85,24.43,19.38l-1,1.81c-3.62.7-7.24,1.38-10.84,2.12-16.1,3.3-27.76,12.62-35.53,26.84-4.22,7.73-7.9,15.77-11.78,23.69-17.7,36.17-47.72,55.76-86,64.79Q121.62,154.87,91.12,164C81.73,166.86,73,171.76,62.56,176.32Z"
                      />
                    </g>
                  </g>
                </svg>
              </Grid>

              <Grid
                xs={6}
                item
                style={{ display: "flex", justifyContent: "center" }}
              >
                <p style={{ fontSize: "24px" }} className="head1">
                  Order Tracking Status
                </p>
              </Grid>

              <Grid
                xs={3}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {["left"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <MenuIcon
                          onClick={toggleDrawer(anchor, true)}
                          style={{
                            color: "#f4f4f4",
                            height: "35px",
                            width: "35px",
                            paddingRight: 10,
                            cursor: "pointer",
                          }}
                        />
                        <SwipeableDrawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                          // style={{background:'cyan'}}
                          PaperProps={{
                            style: {
                              background: "#f8f8f8",
                              color: "181818",
                              width: 300,
                            },
                          }}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                {/* <p
          style={{ color: "#fff", textAlign: "right" }}
          className="head3"
        >
          {moment()
            .utcOffset(state?.data?.userTZ || "GMT+00:00")
            .format("HH:mm")}{" "}
        </p> */}

                {/* <p style={{ color: "#fff", textAlign: "right" }} className="headd3">
          {moment()
            .utcOffset(state?.data?.userTZ || "GMT+00:00")
            .format("dddd DD MMMM YYYY")}
        </p>
        <p style={{ color: "#fff", textAlign: "right" }} className="headd3">
          {`${state?.data?.userCity || "-"}/${
            state?.data?.userCountry || "-"
          }` || "-/-"}
        </p> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {!state?.screen_render && (
        <>
          {/* For Web */}

          {/* Header Start */}
          <Grid
            container
            className="hide-mobile headerBackground"
            style={{ height: 64 }}
          >
            {toggleDrawer(state?.anchor, false)}
            <Grid
              xs={3}
              item
              style={{ paddingLeft: 10, display: "flex", alignItems: "center" }}
            >
              <svg
                style={{ fill: "#ffffff", height: "50px", width: "50px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 297.68 176.32"
              >
                <title>PWA-Logistics</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M14.68,86,70.81,54l-.67-1.59c-6.57,1.72-13.07,3.9-19.75,5-4.75.79-10,1.18-14.58,0C23.74,54.13,11.92,49.9,0,46.05L.16,44l77.9-20.27-.19-1.36c-9.33,0-18.68.29-28-.13-4.25-.19-9-.83-12.53-2.92C28.16,13.86,19.52,7.49,9.22.51,12.3.23,13.6,0,14.91,0Q87,.65,159.05,1.4c5.65.07,11.49.33,16.89,1.79,14.45,3.88,23.32,20.49,20.76,36.7C194.27,55.27,185.25,65,171.78,71.71c-15.9,8-33,11.14-50.29,13.64-30.78,4.44-61.69,4.93-92.67,2.51C24.7,87.54,20.61,86.79,14.68,86Z"
                    />
                    <path
                      className="cls-1"
                      d="M62.56,176.32c2.1-3.22,3.4-5.59,5-7.68,5.33-6.77,11.39-13.06,16.06-20.25,6.91-10.63,18.59-12.37,28.78-16.71,29-12.34,55.4-28.6,77-51.63C201.15,67.56,211,53.34,210.12,34.8c-.2-4.58-.54-9.41-2.21-13.58s0-6.37,2.77-8.47C230.26-2,252.47-3.35,273.25,8.93c8.86,5.25,16.33,12.85,24.43,19.38l-1,1.81c-3.62.7-7.24,1.38-10.84,2.12-16.1,3.3-27.76,12.62-35.53,26.84-4.22,7.73-7.9,15.77-11.78,23.69-17.7,36.17-47.72,55.76-86,64.79Q121.62,154.87,91.12,164C81.73,166.86,73,171.76,62.56,176.32Z"
                    />
                  </g>
                </g>
              </svg>
            </Grid>
            <Grid
              xs={5}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="head1"
                style={{ fontSize: "30px", paddingLeft: "20px" }}
              >
                Order Tracking Status
              </p>
            </Grid>

            <Grid
              xs={4}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
                paddingRight: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  className="text22"
                  style={{
                    paddingRight: "20px",
                    color: "#f4f4f4",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <PDFDownloadLink
                    style={{
                      textDecoration: "none",
                    }}
                    document={
                      <TrackInformationPDF
                        lastTracking={lastTracking}
                        {...props}
                      />
                    }
                    fileName={`TrackingPage.pdf`}
                  >
                    <span
                      className="text22"
                      style={{
                        color: "#f4f4f4",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Print{" "}
                    </span>
                  </PDFDownloadLink>
                </span>
                <span
                  onClick={() => {
                    handleClickOpen();
                  }}
                  className="text22"
                  style={{
                    paddingRight: "20px",
                    color: "#f4f4f4",
                    cursor: "pointer",
                  }}
                >
                  Time Zone
                </span>
                <span
                  onClick={() => {
                    navigate(`/track`);
                  }}
                  className="text22"
                  style={{
                    paddingRight: "20px",
                    color: "#f4f4f4",
                    cursor: "pointer",
                  }}
                >
                  All Orders
                </span>

                <span
                  className="text22"
                  onClick={() => {
                    handleSupportOpenDialog();
                  }}
                  style={{
                    paddingRight: "20px",
                    color: "#f4f4f4",
                    cursor: "pointer",
                  }}
                >
                  Support
                </span>

                <span
                  onClick={() => {
                    handleAboutOpen();
                  }}
                  className="text22"
                  style={{
                    paddingRight: "20px",
                    color: "#f4f4f4",
                    cursor: "pointer",
                  }}
                >
                  About
                </span>

                {/* <span>
            <SettingsIcon
              onClick={() => {
                handleClickOpen();
              }}
              style={{
                color: "#f4f4f4",
                height: "35px",
                width: "35px",
                paddingRight: 10,
                cursor: "pointer",
              }}
            />
          </span> */}
              </div>
              {/* <p
          style={{ color: "#fff", textAlign: "right" }}
          className="head3"
        >
          {moment()
            .utcOffset(state?.data?.userTZ || "GMT+00:00")
            .format("HH:mm")}{" "}
        </p> */}

              {/* <p style={{ color: "#fff", textAlign: "right" }} className="headd3">
          {moment()
            .utcOffset(state?.data?.userTZ || "GMT+00:00")
            .format("dddd DD MMMM YYYY")}
        </p>
        <p style={{ color: "#fff", textAlign: "right" }} className="headd3">
          {`${state?.data?.userCity || "-"}/${
            state?.data?.userCountry || "-"
          }` || "-/-"}
        </p> */}
            </Grid>
          </Grid>
          {/* Header End */}

          <div className="hide-mobile">
            <Grid
              container
              style={{
                borderRadius: "15px",
                padding: 35,
                background: "#f4f4f4",
              }}
            >
              <Grid container spacing={4}>
                {/* Card Web View Work Here */}
                <Grid xs={5} item>
                  <Accordion
                    style={{ padding: "0px" }}
                    className="accordion_panel_border"
                    defaultExpanded
                  >
                    <AccordionSummary
                      style={{ background: "#E6E6FA" }}
                      className="summaryHead"
                    >
                      <Typography>
                        <p className="accordion_head">Tracking Info</p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          margin: 0,
                          zIndex: 1,
                          marginTop: 10,
                          width: "100%",
                          backgroundColor: "#fff",
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "#fff",
                            borderRadius: 10,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={12} md={6} item>
                              <div>
                                <label
                                  style={{
                                    paddingLeft: "13px",
                                    marginBottom: 5,
                                  }}
                                  className="text22"
                                >
                                  Order Tracking No.
                                </label>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={params?.order_id}
                                  className="font_c"
                                  inputProps={{
                                    style: {
                                      fontFamily: "font_c",
                                    },
                                  }}
                                  InputProps={{ readOnly: true }}
                                  style={{
                                    marginTop: 5,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid
                              xs={12}
                              md={6}
                              item
                              style={{ marginTop: "2px" }}
                            >
                              <FormControl fullWidth>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <label
                                    style={{
                                      paddingLeft: "13px",
                                    }}
                                    className="text22"
                                  >
                                    Shipping ID
                                  </label>
                                </div>
                                <div>
                                  <Select
                                    className="text1"
                                    style={{
                                      display: "flex",
                                      fontFamily: "font_c",
                                      marginTop: 5,
                                    }}
                                    fullWidth
                                    value={ship_id}
                                    sx={{
                                      "&.MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputSizeSmall css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                        {
                                          display: "flex",
                                          flexDirection: "row",
                                          background: "cyan",
                                        },
                                    }}
                                    onChange={handleChange}
                                  >
                                    <MenuItem
                                      className="text1"
                                      value={0}
                                      style={{
                                        fontFamily: "font_c",
                                      }}
                                    >
                                      All Shippings
                                    </MenuItem>
                                    {allShippings.map((i) => (
                                      <MenuItem
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          position: "relative",
                                          fontFamily: "font_c",
                                        }}
                                        className="text1"
                                        value={i.id}
                                      >
                                        {i?.label}
                                        <img
                                          src={
                                            i?.logs[0]?.mot === "Air"
                                              ? airplane
                                              : i?.logs[0]?.mot === "Road"
                                              ? truck
                                              : i?.logs[0]?.mot === "Sea"
                                              ? ship
                                              : i?.logs[0]?.mot === "Rail"
                                              ? train
                                              : "-"
                                          }
                                          style={{
                                            height: "28px",
                                            width: "27px",
                                            position: "absolute",
                                            right: "31px",
                                          }}
                                          alt=""
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "15px",
                            }}
                          >
                            <span
                              className="head33"
                              style={{ paddingLeft: "14px" }}
                            >
                              Recent Status
                            </span>
                          </Grid>

                          <Grid
                            xs={12}
                            item
                            style={{
                              border: "1px #d9d9d9 solid",
                              borderTop: "1px #d9d9d9 solid",
                              borderRadius: "10px",
                              borderBottom: "3px #d9d9d9 solid",
                              paddingTop: "23px",
                              paddingBottom: "20px",
                              marginTop: "5px",
                            }}
                          >
                            <Grid xs={12} item style={{ paddingLeft: "14px" }}>
                              <Grid container>
                                <Grid
                                  xs={4}
                                  item
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="text1"
                                    style={{ lineHeight: 2 }}
                                  >
                                    <span className="text2"> Status </span>
                                    <br />

                                    <div
                                      className="card-para text1"
                                      style={{
                                        marginBottom: 5,
                                        color: "#3344b5",
                                      }}
                                    >
                                      {lastTracking?.tracking_status === "Expected Start" ? "Schedule Start" : lastTracking?.tracking_status || "-"}
                                    </div>
                                  </div>
                                </Grid>
                                <Grid xs={4} item>
                                  <div
                                    className="text1"
                                    style={{ lineHeight: 2 }}
                                  >
                                    <span className="text2"> Date </span>
                                    <br />
                                    <div
                                      className="card-para text1"
                                      style={{
                                        marginBottom: 5,
                                        color: "#3344b5",
                                      }}
                                    >
                                      {moment(
                                        lastTracking?.tracking_date
                                      ).format("DD MMM YYYY") || "-"}{" "}
                                    </div>
                                  </div>
                                </Grid>
                                <Grid xs={4} item>
                                  <div
                                    className="text1"
                                    style={{ lineHeight: 2 }}
                                  >
                                    <span className="text2"> Time </span>
                                    <br />
                                    <div
                                      className="card-para text1"
                                      style={{
                                        marginBottom: 2,
                                        color: "#3344b5",
                                      }}
                                    >
                                      {lastTracking?.tracking_time
                                        ? moment(
                                            `2023-01-01T${lastTracking?.tracking_time}`
                                          ).format("HH:mm")
                                        : "---"}{" "}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Routes work start */}
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                              marginBottom: "5px",
                            }}
                          >
                            <span
                              className="head33"
                              style={{ paddingLeft: "14px" }}
                            >
                              Route(s)
                            </span>
                          </Grid>
                          <div
                            style={{
                              border: "1px #d9d9d9 solid",
                              borderRadius: "10px",
                              borderTop: "1px #d9d9d9 solid",
                              borderBottom: "3px #d9d9d9 solid",

                              boxShadow: "none",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid xs={6} item>
                                <div
                                  style={{ position: "relative", padding: 10 }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={laodingLocationIcon}
                                        alt="Loading Location"
                                        height="28px"
                                        width="28px"
                                      />
                                    </div>
                                    &nbsp;
                                    <br />
                                    <div>
                                      <div className="text1">
                                        <div
                                          style={{ lineHeight: 2 }}
                                          className="text2"
                                        >
                                          From
                                        </div>
                                        <div style={{ marginBottom: 5 }}>
                                          {lastTracking?.c_order_loading_add_city ||
                                            "-"}
                                          ,
                                          {lastTracking?.c_order_loading_add_country ||
                                            "-"}
                                        </div>
                                        <div style={{ marginBottom: 5 }}>
                                          {!lastTracking?.c_order_etd
                                            ? "---"
                                            : lastTracking?.c_order_etd &&
                                              !lastTracking?.c_order_etd_time
                                            ? moment(
                                                lastTracking?.c_order_etd
                                              ).format("DD MMM YYYY")
                                            : lastTracking?.c_order_etd &&
                                              lastTracking?.c_order_etd_time
                                            ? `${moment(
                                                lastTracking?.c_order_etd
                                              ).format("DD MMM YYYY")} 
                            ${lastTracking?.c_order_etd_time.substring(0, 5)} ${
                                                lastTracking?.c_order_etd_tz
                                                  ? lastTracking?.c_order_etd_tz
                                                  : "-"
                                              }`
                                            : "---"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {[0, 13, 25, 37, 48].map((dot, idx) => (
                                      <span
                                        key={idx.toString()}
                                        style={{
                                          color: "grey",
                                          fontSize: 40,
                                          position: "absolute",
                                          left: 20,
                                          top: dot,
                                        }}
                                      >
                                        .
                                      </span>
                                    ))}
                                  </div>

                                  <div
                                    className="card-para"
                                    style={{
                                      display: "flex",
                                      marginBottom: 5,
                                      marginTop: 5,
                                    }}
                                  >
                                    <img
                                      src={laodingIcon}
                                      alt="Loading Details"
                                      height="28px"
                                      width="28px"
                                    />
                                    &nbsp;
                                    <div className="margin0">
                                      <div className="margin0 text1">
                                        <div
                                          style={{ lineHeight: 2 }}
                                          className="text2"
                                        >
                                          To
                                        </div>
                                        <div
                                          style={{ marginBottom: 5 }}
                                          className="text1"
                                        >
                                          {lastTracking?.c_order_unloading_add_city ||
                                            "-"}
                                          ,
                                          {lastTracking?.c_order_unloading_add_country ||
                                            "-"}
                                        </div>
                                        <div
                                          style={{ marginBottom: 5 }}
                                          className="text1"
                                        >
                                          {!lastTracking?.c_order_eta
                                            ? "---"
                                            : lastTracking?.c_order_eta &&
                                              !lastTracking?.c_order_eta_time
                                            ? moment(
                                                lastTracking?.c_order_eta
                                              ).format("DD MMM YYYY")
                                            : lastTracking?.c_order_eta &&
                                              lastTracking?.c_order_eta_time
                                            ? `${moment(
                                                lastTracking?.c_order_eta
                                              ).format("DD MMM YYYY")} 
                            ${lastTracking?.c_order_eta_time.substring(0, 5)} ${
                                                lastTracking?.c_order_eta_tz
                                                  ? lastTracking?.c_order_eta_tz
                                                  : "-"
                                              }`
                                            : "---"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>

                              {/* ETA */}

                              {/* Web Stepper Start */}
                              <Grid xs={12} item>
                                <div style={{ padding: "10px" }}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      marginTop: "10px",
                                      marginBottom: "-10px",
                                    }}
                                  >
                                    <Stepper
                                      activeStep={
                                        lastTracking?.tracking_status ? 3 : 3
                                      }
                                      alternativeLabel
                                    >
                                      <Step
                                        style={{ fontFamily: "font_d" }}
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status ===
                                                null
                                                  ? "#9f9f9f"
                                                  : !lastTracking?.tracking_status
                                                  ? "#9f9f9f"
                                                  : lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status?.includes(
                                                      "End:"
                                                    )
                                                  ? "#C31919"
                                                
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  : "#3344B5"
                                              }`,
                                            },
                                        }}
                                      >
                                        <StepLabel>{""}</StepLabel>
                                      </Step>
                                      <Step
                                        style={{ fontFamily: "font_d" }}
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status ===
                                                null
                                                  ? "#9f9f9f"
                                                  : !lastTracking?.tracking_status
                                                  ? "#9f9f9f"
                                                  : lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status?.includes(
                                                      "End:"
                                                    )
                                                  ? "#C31919"
                                                 
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status !==
                                                      "Pick" ||
                                                    "In-Transit" ||
                                                    "Stop" ||
                                                    "Start" ||
                                                    "Start: home" ||
                                                    "Start: preparing" ||
                                                    "Start: job switched" ||
                                                    "Start: job pending"
                                                  ? "#3344B5"
                                                  : "#9f9f9f"
                                              }`,
                                            },
                                        }}
                                      >
                                        <StepLabel
                                          className="step"
                                          style={{ fontFamily: "font_d" }}
                                        >
                                          <p>
                                            {lastTracking?.tracking_status ===
                                            "Expected Start"
                                              ? "Schedule Start"
                                              : lastTracking?.tracking_status}
                                          </p>
                                        </StepLabel>
                                      </Step>
                                      <Step
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"  :
                                                  lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"  :
                                                lastTracking?.tracking_status?.includes(
                                                  "End:"
                                                )
                                                  ? "#C31919"
                                                  
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  : "#9f9f9f"
                                              }`,
                                            },
                                        }}
                                      >
                                        <StepLabel> </StepLabel>
                                      </Step>
                                    </Stepper>
                                  </Box>
                                </div>
                              </Grid>
                              {/* Web Stepper End */}
                            </Grid>
                            {/* Routes work End */}
                          </div>

                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <span
                              className="head33"
                              style={{ paddingLeft: "14px" }}
                            >
                              Load Info
                            </span>
                          </Grid>

                          <Grid
                            xs={12}
                            item
                            style={{
                              border: "1px #d9d9d9 solid",
                              borderRadius: "10px",
                              borderTop: "1px #d9d9d9 solid",
                              borderBottom: "3px #d9d9d9 solid",

                              paddingTop: "20px",
                              paddingBottom: "20px",
                              marginTop: "5px",
                            }}
                          >
                            {lastTracking?.movement_type === "Bilty"
                              ? lastTracking?.resource_booking_ids?.map(
                                  (i, idx) => (
                                    <Grid xs={12} item>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={1}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "22px",
                                              width: "22px",
                                              borderRadius: "100px",
                                              background: "#fff",
                                              border: "2px solid #D9D9D9",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <p> {idx + 1}</p>{" "}
                                          </div>
                                        </Grid>
                                        <Grid
                                          xs={4}
                                          item
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className="text1"
                                            style={{ lineHeight: 2 }}
                                          >
                                            <span className="text2"> Load</span>
                                            <br />
                                          </div>
                                          <div
                                            className="card-para text1"
                                            style={{ marginBottom: 5 }}
                                          >
                                            {i?.load_name || "-"}
                                          </div>
                                        </Grid>
                                        <Grid xs={4} item>
                                          <div
                                            style={{
                                              lineHeight: 2,
                                            }}
                                          >
                                            <span className="text2">
                                              {" "}
                                              Weight{" "}
                                            </span>
                                            <br />
                                            <div
                                              className="card-para"
                                              style={{ marginBottom: 5 }}
                                            >
                                              {i?.load_weight || "-"}{" "}
                                              {i?.load_weight_unit || "-"}
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid xs={3} item>
                                          <div
                                            className="text1"
                                            style={{ lineHeight: 2 }}
                                          >
                                            <span className="text2">
                                              {" "}
                                              Load Item{" "}
                                            </span>
                                            <br />
                                            <div
                                              className="card-para"
                                              style={{ marginBottom: 5 }}
                                            >
                                              {i?.no_of_item || "-"}{" "}
                                              {i?.no_of_item_unit || "-"}
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )
                                )
                              : lastTracking?.customer_loads_info?.map(
                                  (i, idx) => (
                                    <Grid xs={12} item key={idx.toString()}>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={1}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "22px",
                                              width: "22px",
                                              borderRadius: "100px",
                                              background: "#fff",
                                              border: "2px solid #D9D9D9",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <p> {idx + 1}</p>{" "}
                                          </div>
                                        </Grid>

                                        <Grid
                                          xs={4}
                                          item
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className="text1"
                                            style={{ lineHeight: 2 }}
                                          >
                                            <span className="text2">
                                              {" "}
                                              Load{" "}
                                            </span>
                                            <br />

                                            <div
                                              className="card-para text1"
                                              style={{ marginBottom: 5 }}
                                            >
                                              {i?.load_name || "-"}
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid xs={4} item>
                                          <div
                                            className="text1"
                                            style={{ lineHeight: 2 }}
                                          >
                                            <span className="text2">
                                              {" "}
                                              Weight{" "}
                                            </span>
                                            <br />
                                            <div
                                              className="card-para text1"
                                              style={{ marginBottom: 5 }}
                                            >
                                              {i?.weight || "-"}{" "}
                                              {i?.weight_unit || "-"}
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid xs={3} item>
                                          <div
                                            className="text1"
                                            style={{ lineHeight: 2 }}
                                          >
                                            <span className="text2">
                                              {" "}
                                              Load Item{" "}
                                            </span>
                                            <br />
                                            <div
                                              className="card-para text1"
                                              style={{ marginBottom: 2 }}
                                            >
                                              {i?.item_name || "-"}{" "}
                                              {i?.item_unit || "-"}
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {lastTracking?.customer_loads_info
                                        ?.length ===
                                      idx + 1 ? null : (
                                        <hr style={{ color: "#D9D9D9" }} />
                                      )}
                                    </Grid>
                                  )
                                )}
                          </Grid>

                          {/* Support */}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid xs={7} item>
                  <Accordion className="accordion_panel_border" defaultExpanded>
                    <AccordionSummary
                      style={{ background: "#E6E6FA" }}
                      className="summaryHead"
                    >
                      <Typography>
                        <p className="accordion_head">Tracking Map</p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ minHeight: "100%" }}>
                        <MapContainer
                          scrollWheelZoom={true}
                          ref={handleMap}
                          center={direction}
                          zoom={13}
                          style={{
                            height: "600px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <TileLayer
                            attribution=' <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />

                          <Marker
                            position={[
                              lastTracking?.c_order_loading_add_lat || 0,
                              lastTracking?.c_order_loading_add_lng || 0,
                            ]}
                          >
                            <Popup style={{ border: "2px solid red" }}>
                              <h2
                                style={{
                                  color: "#0050ff",
                                  fontFamily: "font_c",
                                }}
                              >
                                Origin:
                                <span
                                  style={{ fontSize: "16px", color: "black" }}
                                >
                                  &nbsp;
                                  {`${lastTracking?.c_order_loading_add_city},
                  ${lastTracking?.c_order_loading_add_country}` || "-"}
                                </span>
                              </h2>
                            </Popup>
                          </Marker>

                          <Marker
                            position={[
                              lastTracking?.c_order_unloading_add_lat || 0,
                              lastTracking?.c_order_unloading_add_lng || 0,
                            ]}
                          >
                            <Popup
                              style={{
                                border: "2px solid red",
                                background: "red",
                              }}
                            >
                              <div
                                style={{
                                  height: "60px",
                                  minWidth: "100%",
                                  maxWidth: "100%",
                                }}
                              >
                                <h2
                                  style={{
                                    color: "#0050ff",
                                    fontFamily: "font_c",
                                  }}
                                >
                                  Destination:
                                  <span
                                    style={{ fontSize: "16px", color: "black" }}
                                  >
                                    &nbsp;
                                    {lastTracking?.c_order_unloading_add_city ||
                                      "-"}
                                    ,
                                    <br />
                                    {lastTracking?.c_order_unloading_add_country ||
                                      "-"}
                                  </span>
                                </h2>
                              </div>
                            </Popup>
                          </Marker>

                          {trackingDetails.map((item, index) => {
                            return item.latitude && item.longitude ? (
                              <div key={index} style={{ fontFamily: "font_d" }}>
                                <Marker
                                  position={[
                                    item?.latitude || 0,
                                    item?.longitude || 0,
                                  ]}
                                >
                                  <Popup style={{ border: "2px solid red" }}>
                                    <h1
                                      style={{
                                        color: "#0050ff",
                                        fontFamily: "font_c",
                                      }}
                                    >
                                      {item?.tracking_status}
                                    </h1>
                                    <span
                                      style={{
                                        color: "#09193e ",
                                        fontFamily: "font_c",
                                        fontSize: "17px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      Date:{" "}
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontFamily: "font_d",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {moment(item?.tracking_date).format(
                                        "DD MMM YYYY"
                                      ) || "---"}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        color: "#09193e ",
                                        fontSize: "17px",
                                        fontFamily: "font_c",

                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      Time:{" "}
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontFamily: "font_d",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item?.tracking_time || "-"}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        color: "#09193e ",
                                        fontSize: "17px",
                                        fontWeight: "bolder",
                                        fontFamily: "font_c",
                                      }}
                                    >
                                      {" "}
                                      Latitude:{" "}
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontFamily: "font_d",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item?.latitude}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        color: "#09193e ",
                                        fontSize: "17px",
                                        fontFamily: "font_c",

                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      Longitude:{" "}
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontFamily: "font_d",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item?.longitude}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        color: "#09193e ",
                                        fontSize: "17px",
                                        fontWeight: "bolder",
                                        fontFamily: "font_c",
                                      }}
                                    >
                                      {" "}
                                      Location:{" "}
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontFamily: "font_d",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {`${item?.location_country || "-"}/${
                                        item?.location_city || "-"
                                      }` ||
                                        item?.location_address ||
                                        "-"}
                                    </span>
                                  </Popup>
                                </Marker>
                              </div>
                            ) : null;
                          })}
                          {isMapinit && (
                            <RoutingMachine
                              map={_map}
                              draggable={false}
                              showAlternatives={false}
                              waypoints={wayPoints || []}
                            />
                          )}
                        </MapContainer>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid xs={12} item>
                  <Accordion className="accordion_panel_border" defaultExpanded>
                    <AccordionSummary
                      style={{ background: "#E6E6FA" }}
                      className="summaryHead"
                    >
                      <p className="accordion_head"> Tracking Logs</p>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: "0px", padding: "0px" }}>
                      <TableContainer style={{ marginTop: "6px" }}>
                        <Table
                          style={{ maxHeight: "40vh", overflow: "scroll" }}
                        >
                          <TableHead>
                            <TableRow style={{ background: "#EEEEEE" }}>
                              <TableCell className={"th-cell"}>
                                &emsp;Date
                              </TableCell>
                              <TableCell className={"th-cell"}>Time</TableCell>
                              <TableCell className={"th-cell"}>
                                Status
                              </TableCell>
                              <TableCell className={"th-cell"}>
                                Location
                              </TableCell>
                              <TableCell className={"th-cell"}>
                                Load Info
                              </TableCell>

                              {ship_id === 0 ? (
                                <TableCell className="th-cell">Mot</TableCell>
                              ) : (
                                <TableCell className="th-cell">
                                  {trackingDetails[0]?.mot === "Sea"
                                    ? "Vessel No"
                                    : trackingDetails[0]?.mot === "Road"
                                    ? "Vehicle No"
                                    : trackingDetails[0]?.mot === "Air"
                                    ? "AWB No"
                                    : trackingDetails[0]?.mot === "Rail"
                                    ? "Wagon No"
                                    : "Vehicle No"}
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {trackingDetails?.map((i, index) => {
                              return i?.tracking_status ===
                                "Expected Start" ? null : (
                                <TableRow key={index}>
                                  <TableCell className="td-cell">
                                    &nbsp;{" "}
                                    {moment(
                                      `${moment(i?.tracking_date).format(
                                        "YYYY-MM-DD"
                                      )}T${i?.tracking_time}${(
                                        i?.location_zone || "GMT+00:00"
                                      )
                                        ?.replace("GMT", "")
                                        .replace(" ", "+")}`
                                    )
                                      .utcOffset(
                                        state?.data?.userTZ || current_zone
                                      )
                                      .format("DD-MMM-YYYY")}
                                  </TableCell>
                                  <TableCell className="td-cell">
                                    {moment(
                                      `${moment(i?.tracking_date).format(
                                        "YYYY-MM-DD"
                                      )}T${i?.tracking_time}${(
                                        i?.location_zone || "GMT+00:00"
                                      )
                                        ?.replace("GMT", "")
                                        .replace(" ", "+")}`
                                    )
                                      .utcOffset(
                                        state?.data?.userTZ || current_zone
                                      )
                                      .format("HH:mm")}
                                  </TableCell>

                                  <TableCell className="td-cell">
                                    {i?.tracking_status || "-"}
                                  </TableCell>
                                  <TableCell className="td-cell">
                                    {i?.location_city ||
                                      i?.location_address ||
                                      "-"}
                                    ,&nbsp;
                                    {i?.location_country || "-"}
                                  </TableCell>
                                  <TableCell className="td-cell">
                                    {i?.movement_type !== "Bilty"
                                      ? i?.resource_load_ids?.map(
                                          (j, idxxx) => {
                                            return (
                                              <span>
                                                {j?.c_order_load_name || "-"}
                                                {i?.resource_load_ids
                                                  ?.length !==
                                                  idxxx + 1 && ","}
                                              </span>
                                            );
                                          }
                                        )
                                      : i?.resource_booking_ids?.map(
                                          (j, idxxx) => {
                                            return (
                                              <span>
                                                {j?.load_name || "-"}
                                                {i?.resource_booking_ids
                                                  ?.length !==
                                                  idxxx + 1 && ","}
                                              </span>
                                            );
                                          }
                                        )}
                                  </TableCell>

                                  {ship_id === 0 ? (
                                    <TableCell className="td-cell">
                                      {i?.mot || "-"}
                                    </TableCell>
                                  ) : (
                                    <TableCell className="td-cell">
                                      {i?.ship_plan_carrier_no ||
                                        i?.bilty_vehicle_no ||
                                        "-"}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/* Tracking Table For Web View  end Here*/}

          <div
            className="hide-web"
            style={{ flexDirection: "column", paddingTop: 60 }}
          >
            <div
              style={{
                position: "fixed",
                height: 300,
                width: "100%",
                objectFit: "contain",
              }}
            >
              <MapContainer
                scrollWheelZoom={true}
                ref={handleMap}
                zoom={13}
                center={direction}
                style={{ height: 400, width: "100%" }}
              >
                <TileLayer
                  attribution=' <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    lastTracking?.c_order_loading_add_lat || 0,
                    lastTracking?.c_order_loading_add_lng || 0,
                  ]}
                >
                  <Popup style={{ border: "2px solid red" }}>
                    <div style={{ height: "60px" }}>
                      <h2 style={{ color: "#0050ff", fontFamily: "font_c" }}>
                        Origin:
                        <span
                          style={{
                            fontSize: "16px",
                            color: "black",
                            fontFamily: "font_d",
                          }}
                        >
                          &nbsp; {lastTracking?.c_order_loading_add_city},
                          <br />
                          {lastTracking?.c_order_loading_add_country}
                        </span>
                      </h2>
                    </div>
                  </Popup>
                </Marker>

                <Marker
                  position={[
                    lastTracking?.c_order_unloading_add_lat || 0,
                    lastTracking?.c_order_unloading_add_lng || 0,
                  ]}
                >
                  <Popup style={{ border: "2px solid red", background: "red" }}>
                    <h2 style={{ color: "#0050ff", fontFamily: "font_c" }}>
                      Destination:
                      <span
                        style={{
                          fontSize: "16px",
                          color: "black",
                          fontFamily: "font_d",
                        }}
                      >
                        &nbsp; {lastTracking?.c_order_unloading_add_city},
                        <br />
                        {lastTracking?.c_order_unloading_add_country}
                      </span>
                    </h2>
                  </Popup>
                </Marker>
                {trackingDetails.map((item, index) => {
                  return item.latitude && item.longitude ? (
                    <div key={index}>
                      <Marker
                        position={[item?.latitude || 0, item?.longitude || 0]}
                      >
                        <Popup style={{ border: "2px solid red" }}>
                          <h1
                            style={{
                              color: "#0050ff",
                              fontFamily: "font_c",
                            }}
                          >
                            {item?.tracking_status}
                          </h1>
                          <span
                            style={{
                              color: "#09193e ",
                              fontSize: "17px",
                              fontWeight: "bolder",
                              fontFamily: "font_c",
                            }}
                          >
                            Date:{" "}
                          </span>{" "}
                          <span
                            style={{ fontFamily: "font_d", fontSize: "14px" }}
                          >
                            {item?.tracking_date
                              ? moment(item?.tracking_date).format(
                                  "DD MMM YYYY"
                                )
                              : "--"}
                          </span>
                          <br />
                          <span
                            style={{
                              color: "#09193e ",
                              fontSize: "17px",
                              fontFamily: "font_c",
                              fontWeight: "bolder",
                            }}
                          >
                            Time:{" "}
                          </span>{" "}
                          <span
                            style={{ fontFamily: "font_d", fontSize: "14px" }}
                          >
                            {item?.tracking_time || "--"}
                          </span>
                          <br />
                          <span
                            style={{
                              color: "#09193e ",
                              fontFamily: "font_c",
                              fontSize: "17px",
                              fontWeight: "bolder",
                            }}
                          >
                            Latitude:{" "}
                          </span>{" "}
                          <span
                            style={{ fontFamily: "font_d", fontSize: "14px" }}
                          >
                            {item?.latitude}
                            <br />
                          </span>
                          <span
                            style={{
                              color: "#09193e ",
                              fontSize: "17px",
                              fontWeight: "bolder",
                              fontFamily: "font_c",
                            }}
                          >
                            Longitude:{" "}
                          </span>{" "}
                          <span
                            style={{ fontFamily: "font_d", fontSize: "14px" }}
                          >
                            {item?.longitude}
                          </span>
                          <br />
                          <span
                            style={{
                              color: "#09193e ",
                              fontFamily: "font_c",
                              fontSize: "17px",
                              fontWeight: "bolder",
                            }}
                          >
                            {" "}
                            Location:{" "}
                          </span>{" "}
                          <span
                            style={{ fontFamily: "font_d", fontSize: "14px" }}
                          >
                            {`${item?.location_city}/${item?.location_country}` ||
                              item?.location_address ||
                              "-"}
                          </span>
                        </Popup>
                      </Marker>
                    </div>
                  ) : null;
                })}
                {isMapinit && (
                  <RoutingMachine
                    map={_map}
                    draggable={false}
                    showAlternatives={false}
                    waypoints={wayPoints || []}
                  />
                )}
              </MapContainer>
            </div>
            <div
              style={{
                zIndex: 1,
                marginTop: 325,
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
                borderRadius: 10,
                // padding: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "#fff",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderBottom: "2px #d9d9d9 solid",
                    padding: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <h2
                      style={{
                        margin: 0,
                        color: "#0f1436",
                        fontFamily: "font_d",
                        paddingLeft: "11px",
                      }}
                    >
                      Tracking Info
                    </h2>
                  </div>
                  <div style={{ paddingRight: "10px" }}>
                    <Button
                      variant="outlined"
                      style={{ color: "#3344b5", outlineColor: "#F8F8F8" }}
                      startIcon={<ArrowBackIcon color="#3344b5" />}
                      onClick={() => {
                        navigate(`/track`);
                      }}
                    >
                      All Orders
                    </Button>
                  </div>
                </div>
                {/* Mobile View */}
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={12} md={6} item>
                    <div>
                      <label
                        style={{
                          paddingLeft: "13px",
                        }}
                        className="text22"
                      >
                        Order Tracking No.
                      </label>
                      <TextField
                        variant="outlined"
                        className="font_c"
                        fullWidth
                        value={params?.order_id}
                        inputProps={{
                          style: {
                            fontFamily: "font_c",
                          },
                        }}
                        style={{
                          marginTop: 5,
                        }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} md={6} item style={{ marginTop: "2px" }}>
                    <FormControl fullWidth>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label
                          style={{
                            paddingLeft: "13px",
                          }}
                          className="text22"
                        >
                          Shipping ID
                        </label>
                      </div>
                      <div>
                        <Select
                          className="text1"
                          style={{
                            display: "flex",
                            fontFamily: "font_c",
                            marginTop: 5,
                          }}
                          fullWidth
                          value={ship_id}
                          sx={{
                            "&.MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputSizeSmall css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                display: "flex",
                                flexDirection: "row",
                                background: "cyan",
                              },
                          }}
                          onChange={handleChange}
                        >
                          <MenuItem
                            className="text1"
                            value={0}
                            style={{ fontFamily: "font_c" }}
                          >
                            All Shippings
                          </MenuItem>
                          {allShippings.map((i) => (
                            <MenuItem
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                                fontFamily: "font_c",
                              }}
                              className="text1"
                              value={i.id}
                            >
                              {i?.label}
                              <img
                                src={
                                  i?.logs[0]?.mot === "Air"
                                    ? airplane
                                    : i?.logs[0]?.mot === "Road"
                                    ? truck
                                    : i?.logs[0]?.mot === "Sea"
                                    ? ship
                                    : i?.logs[0]?.mot === "Rail"
                                    ? train
                                    : "-"
                                }
                                style={{
                                  height: "28px",
                                  width: "27px",
                                  position: "absolute",
                                  right: "31px",
                                }}
                                alt=""
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid xs={12} item>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        <span
                          className="head33"
                          style={{ paddingLeft: "14px" }}
                        >
                          Recent Status
                        </span>
                      </Grid>

                      <Grid
                        xs={12}
                        item
                        style={{
                          border: "1px #d9d9d9 solid",
                          borderTop: "1px #d9d9d9 solid",
                          borderBottom: "3px #d9d9d9 solid",
                          borderRadius: "10px",
                          paddingTop: "23px",
                          paddingBottom: "20px",
                          marginTop: "5px",
                        }}
                      >
                        <Grid xs={12} item style={{ paddingLeft: "14px" }}>
                          <Grid container>
                            <Grid
                              xs={4}
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div className="text1" style={{ lineHeight: 2 }}>
                                <span className="text2"> Status </span>
                                <br />

                                <div
                                  className="card-para text1"
                                  style={{ marginBottom: 5, color: "#3344b5" }}
                                >
                                      {lastTracking?.tracking_status === "Expected Start" ? "Schedule Start" : lastTracking?.tracking_status || "-"}
                                </div>
                              </div>
                            </Grid>
                            <Grid xs={4} item>
                              <div className="text1" style={{ lineHeight: 2 }}>
                                <span className="text2"> Date </span>
                                <br />
                                <div
                                  className="card-para text1"
                                  style={{ marginBottom: 5, color: "#3344b5" }}
                                >
                                  {moment(lastTracking?.tracking_date).format(
                                    "DD MMM YYYY"
                                  ) || "-"}{" "}
                                </div>
                              </div>
                            </Grid>
                            <Grid xs={4} item>
                              <div className="text1" style={{ lineHeight: 2 }}>
                                <span className="text2"> Time </span>
                                <br />
                                <div
                                  className="card-para text1"
                                  style={{ marginBottom: 2, color: "#3344b5" }}
                                >
                                  {lastTracking?.tracking_time
                                    ? moment(
                                        `2023-01-01T${lastTracking?.tracking_time}`
                                      ).format("HH:mm")
                                    : "---"}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "-10px",
                        }}
                      >
                        <p style={{ paddingLeft: "14px" }} className="head33">
                          Routes
                        </p>
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        style={{
                          marginTop: "5px",
                          border: "1px #d9d9d9 solid",
                          borderRadius: "10px",
                          padding: "35px",
                          borderTop: "1px #d9d9d9 solid",
                          borderBottom: "3px #d9d9d9 solid",
                        }}
                      >
                        {"\n"}

                        <Grid container>
                          <Grid xs={12} item style={{ position: "relative" }}>
                            <div style={{ display: "flex" }}>
                              <div>
                                <img
                                  src={laodingLocationIcon}
                                  alt="Loading Location"
                                  height="28px"
                                  width="28px"
                                />
                              </div>
                              &nbsp;
                              <br />
                              <div className="margin0">
                                <div
                                  className="text2"
                                  style={{ lineHeight: 2 }}
                                >
                                  From
                                </div>
                                <div
                                  style={{ marginBottom: 5 }}
                                  className="text1"
                                >
                                  {lastTracking?.c_order_loading_add_city ||
                                    "-"}
                                  ,
                                  {lastTracking?.c_order_loading_add_country ||
                                    "-"}
                                </div>
                                <div
                                  className="margin0 text1"
                                  style={{ marginBottom: 5 }}
                                >
                                  {!lastTracking?.c_order_etd
                                    ? "---"
                                    : lastTracking?.c_order_etd &&
                                      !lastTracking?.c_order_etd_time
                                    ? moment(lastTracking?.c_order_etd).format(
                                        "DD MMM YYYY"
                                      )
                                    : lastTracking?.c_order_etd &&
                                      lastTracking?.c_order_etd_time
                                    ? `${moment(
                                        lastTracking?.c_order_etd
                                      ).format("DD MMM YYYY")} 
                            ${lastTracking?.c_order_etd_time.substring(0, 5)} ${
                                        lastTracking?.c_order_etd_tz
                                          ? lastTracking?.c_order_etd_tz
                                          : "-"
                                      }`
                                    : "---"}
                                </div>
                              </div>
                            </div>
                            <div>
                              {[5, 15, 25, 35, 45].map((dot, idx) => (
                                <span
                                  key={idx.toString()}
                                  style={{
                                    color: "grey",
                                    fontSize: 25,
                                    position: "absolute",
                                    left: 10,
                                    top: dot,
                                  }}
                                >
                                  .
                                </span>
                              ))}
                            </div>

                            <div
                              className="card-para"
                              style={{
                                display: "flex",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              <img
                                src={laodingIcon}
                                alt="Loading Details"
                                height="28px"
                                width="28px"
                              />
                              &nbsp;
                              <div className="margin0">
                                <div
                                  className="margin0 text1"
                                  style={{ lineHeight: 2 }}
                                >
                                  <span className="text2">To</span>
                                </div>

                                <div className="text1">
                                  {lastTracking?.c_order_unloading_add_city ||
                                    "-"}
                                  ,
                                  {lastTracking?.c_order_unloading_add_country ||
                                    "-"}
                                </div>
                                <div>
                                  <p className="margin0">
                                    {!lastTracking?.c_order_eta
                                      ? "---"
                                      : lastTracking?.c_order_eta &&
                                        !lastTracking?.c_order_eta_time
                                      ? moment(
                                          lastTracking?.c_order_eta
                                        ).format("DD MMM YYYY")
                                      : lastTracking?.c_order_eta &&
                                        lastTracking?.c_order_eta_time
                                      ? `${moment(
                                          lastTracking?.c_order_eta
                                        ).format("DD MMM YYYY")} 
                            ${lastTracking?.c_order_eta_time.substring(0, 5)} ${
                                          lastTracking?.c_order_eta_tz
                                            ? lastTracking?.c_order_eta_tz
                                            : "-"
                                        }`
                                      : "---"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Grid>

                          {/* ETA */}

                          {/* Mobile Stepper Start */}
                          <Grid xs={12} item>
                            <Box
                              sx={{
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "-10px",
                              }}
                            >
                              <Stepper
                                      activeStep={
                                        lastTracking?.tracking_status ? 3 : 3
                                      }
                                      alternativeLabel
                                    >
                                      <Step
                                        style={{ fontFamily: "font_d" }}
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status ===
                                                null
                                                  ? "#9f9f9f"
                                                  : !lastTracking?.tracking_status
                                                  ? "#9f9f9f"
                                                  : lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status?.includes(
                                                      "End:"
                                                    )
                                                  ? "#C31919"
                                                
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  : "#3344B5"
                                              }`,
                                            },
                                        }}
                                      >
                                        <StepLabel>{""}</StepLabel>
                                      </Step>
                                      <Step
                                        style={{ fontFamily: "font_d" }}
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status ===
                                                null
                                                  ? "#9f9f9f"
                                                  : !lastTracking?.tracking_status
                                                  ? "#9f9f9f"
                                                  : lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status ===
                                                    "End"
                                                  ? "#049A0F"
                                                  : lastTracking?.tracking_status?.includes(
                                                      "End:"
                                                    )
                                                  ? "#C31919"
                                                 
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  
                                                  : lastTracking?.tracking_status !==
                                                      "Pick" ||
                                                    "In-Transit" ||
                                                    "Stop" ||
                                                    "Start" ||
                                                    "Start: home" ||
                                                    "Start: preparing" ||
                                                    "Start: job switched" ||
                                                    "Start: job pending"
                                                  ? "#3344B5"
                                                  : "#9f9f9f"
                                              }`,
                                            },
                                        }}
                                      >

                                        <StepLabel
                                          className="step"
                                          style={{ fontFamily: "font_d" }}
                                        >
                                          <p>
                                            {lastTracking?.tracking_status ===
                                            "Expected Start"
                                              ? "Schedule Start"
                                              : lastTracking?.tracking_status}
                                          </p>
                                        </StepLabel>
                                      </Step>
                                      <Step
                                        sx={{
                                          "& .MuiStepLabel-root .Mui-completed":
                                            {
                                              color: `${
                                                lastTracking?.tracking_status === "End: delivered successfully"
                                                  ? "#049A0F"  :
                                                  lastTracking?.tracking_status === "End"
                                                  ? "#049A0F"  :
                                                lastTracking?.tracking_status?.includes(
                                                  "End:"
                                                )
                                                  ? "#C31919"
                                                  
                                                  : lastTracking?.tracking_status ===
                                                    "Delivered"
                                                  ? "#049A0F"
                                                  : "#9f9f9f"
                                              }`,
                                            },
                                        }}
                                      >
                                        <StepLabel> </StepLabel>
                                      </Step>
                                    </Stepper>
                            </Box>
                          </Grid>
                          {/* Mobile Stepper End */}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "-10px",
                        }}
                      >
                        <p style={{ paddingLeft: "14px" }} className="head33">
                          Load Info
                        </p>
                      </Grid>

                      <Grid
                        xs={12}
                        item
                        style={{
                          border: "1px #d9d9d9 solid",
                          borderRadius: "10px",
                          padding: "25px",
                          marginTop: "5px",
                          borderTop: "1px #d9d9d9 solid",
                          borderBottom: "3px #d9d9d9 solid",
                        }}
                      >
                        {lastTracking?.movement_type === "Bilty"
                          ? lastTracking?.resource_booking_ids?.map(
                              (i, idx) => (
                                <Grid xs={12} item>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={1}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "25px",
                                          width: "25px",
                                          borderRadius: "100px",
                                          background: "#fff",
                                          border: "2px solid #D9D9D9",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          top: "10px",
                                        }}
                                      >
                                        {idx + 1}
                                      </div>
                                    </Grid>
                                    <Grid
                                      xs={4}
                                      item
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                         
                                      >
                                        <span className="text2">Load</span>
                                        <br />
                                      </div>
                                      <div className="card-para text1">
                                        {i?.load_name || "-"}
                                      </div>
                                    </Grid>
                                    <Grid xs={4} item>
                                      <div>
                                        <span className="text2">Weight</span>
                                        <br />
                                        <div className="text1">
                                          {i?.load_weight || "-"}
                                          {i?.load_weight_unit || "-"}
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <div>
                                        <span className="text2">Load Item</span>
                                        <br />
                                        <div className="card-para">
                                          {i?.no_of_item || "-"}{" "}
                                          {i?.no_of_item_unit || "-"}
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )
                          : lastTracking?.customer_loads_info?.map((i, idx) => (
                              <Grid xs={12} item>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "22px",
                                        width: "22px",
                                        borderRadius: "100px",
                                        background: "#fff",
                                        border: "2px solid #D9D9D9",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingLeft: "-5px",
                                      }}
                                    >
                                      <p> {idx + 1}</p>{" "}
                                    </div>
                                  </Grid>
                                  <Grid
                                    xs={4}
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div>
                                      <span
                                        className="text2"
                                        style={{ lineHeight: 2 }}
                                      >
                                        {" "}
                                        Load{" "}
                                      </span>
                                      <br />
                                    </div>
                                    <div className="card-para text1">
                                      {i?.load_name || "-"}
                                    </div>
                                  </Grid>
                                  <Grid xs={4} item>
                                    <div>
                                      <span
                                        className="text2"
                                        style={{ lineHeight: 2 }}
                                      >
                                        {" "}
                                        Weight{" "}
                                      </span>
                                      <br />
                                      <div className="text1">
                                        {i?.weight || "-"}{" "}
                                        {i?.weight_unit || "-"}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid xs={3} item>
                                    <div>
                                      <span
                                        className="text2"
                                        style={{ lineHeight: 2 }}
                                      >
                                        {" "}
                                        Load Item{" "}
                                      </span>
                                      <br />
                                      <div className="card-para text1">
                                        {i?.item_name || "-"}{" "}
                                        {i?.item_unit || "-"}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>

                                {lastTracking?.customer_loads_info?.length ===
                                idx + 1 ? null : (
                                  <hr style={{ color: "#D9D9D9" }} />
                                )}
                              </Grid>
                            ))}
                      </Grid>

                      {/* Support */}
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className="hide-web">
                {/* Card For Order Details Page in Mobile View */}

                <Grid
                  container
                  spacing={3}
                  style={{
                    marginTop: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  className="hide-web"
                >
                  {trackingDetails.map((i, index) => {
                    return i?.tracking_status === "Expected Start" ? null : (
                      <Grid
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        item
                      >
                        <Card elevation={8} style={{ borderRadius: 20 }}>
                          <CardHeader
                            style={{
                              background:
                                i?.tracking_status === "Delivered"
                                  ? "#459745"
                                  : i?.tracking_status === "End"
                                  ? "#459745"
                                  : i?.tracking_status === "End: delivered successfully"
                                  ? "#459745"
                                  : i?.tracking_status === "Cancelled"
                                  ? "#bf3a3a"
                                  : "#08193e",
                            }}
                            title={
                              <React.Fragment>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      fontFamily: "font_c",
                                      fontSize: 20,
                                      paddingRight: 20,
                                      flex: 1,
                                    }}
                                  >
                                    {(i || {})?.tracking_status}
                                  </span>
                                </div>
                              </React.Fragment>
                            }
                          />
                          <CardContent>
                            <Grid container>
                              <Grid item>
                                <div className="font_c">
                                  {" "}
                                  <span
                                    className="card-para text2"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: 2,
                                    }}
                                  >
                                    Date/Time:
                                  </span>{" "}
                                  {moment(
                                    `${moment(i?.tracking_date).format(
                                      "YYYY-MM-DD"
                                    )}T${i?.tracking_time}${(
                                      i?.location_zone || "GMT+00:00"
                                    )
                                      ?.replace("GMT", "")
                                      .replace(" ", "+")}`
                                  )
                                    .utcOffset(
                                      state?.data?.userTZ || current_zone
                                    )
                                    .format("DD-MMM-YYYY")}{" "}
                                  {moment(
                                    `${moment(i?.tracking_date).format(
                                      "YYYY-MM-DD"
                                    )}T${i?.tracking_time}${(
                                      i?.location_zone || "GMT+00:00"
                                    )
                                      ?.replace("GMT", "")
                                      .replace(" ", "+")}`
                                  )
                                    .utcOffset(
                                      state?.data?.userTZ || current_zone
                                    )
                                    .format("HH:mm")}
                                </div>

                                <div className="font_c">
                                  <span
                                    className="card-para text2"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: 2,
                                    }}
                                  >
                                    Location:
                                  </span>{" "}
                                  {i?.location_city ||
                                    i?.location_address ||
                                    "-"}
                                  ,&nbsp;
                                  {i?.location_country || "-"}
                                </div>

                                <div className="font_c">
                                  <span
                                    className="card-para text2"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: 2,
                                    }}
                                  >
                                    Load Info:
                                  </span>{" "}
                                  {i?.movement_type !== "Bilty"
                                    ? i?.resource_load_ids?.map((j, idxxx) => {
                                        return (
                                          <span>
                                            {j?.c_order_load_name || "-"}
                                            {i?.resource_load_ids?.length !==
                                              idxxx + 1 && ","}
                                          </span>
                                        );
                                      })
                                    : i?.resource_booking_ids?.map(
                                        (j, idxxx) => {
                                          return (
                                            <span>
                                              {j?.load_name || "-"}
                                              {i?.resource_booking_ids
                                                ?.length !==
                                                idxxx + 1 && ","}
                                            </span>
                                          );
                                        }
                                      )}
                                </div>

                                <div className="font_c">
                                  {" "}
                                  <span
                                    className="card-para text2"
                                    style={{
                                      fontSize: "16px",
                                      lineHeight: 2,

                                      fontWeight: "500",
                                    }}
                                  >
                                    {ship_id === 0
                                      ? "Mot:"
                                      : trackingDetails[0]?.mot === "Sea"
                                      ? "Vessel No:"
                                      : trackingDetails[0]?.mot === "Road"
                                      ? "Vehicle No:"
                                      : trackingDetails[0]?.mot === "Air"
                                      ? "Pallet No:"
                                      : trackingDetails[0]?.mot === "Air"
                                      ? "Wagon No:"
                                      : "Vehicle No:"}
                                  </span>{" "}
                                  {ship_id === 0
                                    ? i?.mot
                                    : i?.ship_plan_carrier_no ||
                                      i?.bilty_vehicle_no ||
                                      "-"}
                                </div>

                                <div className="font_c">
                                  <span
                                    className="card-para text2"
                                    style={{
                                      lineHeight: 2,

                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Contact Person:
                                  </span>{" "}
                                  {i?.ship_plan_carrier_contact_name1 ||
                                    i?.bilty_driver_name ||
                                    ""}
                                  {i?.ship_plan_carrier_contact_name2 ||
                                    i?.bilty_driver_name2 ||
                                    ""}
                                </div>

                                <div className="font_c">
                                  {" "}
                                  <span
                                    className="card-para text2"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: 2,
                                    }}
                                  >
                                    Contact No:
                                  </span>{" "}
                                  {i?.ship_plan_carrier_contact_ph1_code ||
                                    i?.ship_plan_carrier_contact_ph2 ||
                                    i?.bilty_driver_phone_code ||
                                    "-"}
                                  {i?.ship_plan_carrier_contact_ph1 ||
                                    i?.ship_plan_carrier_contact_ph2_code ||
                                    i?.bilty_driver_phone ||
                                    "-"}
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>

              {/* Tracking Table For Web View  end Here*/}
            </div>
          </div>
        </>
      )}

      <Dialog
        fullWidth
        onClose={handleClose}
        open={state.showDialog || false}
        style={{ overflow: "hidden" }}
        PaperProps={{
          style: {
            height: 510,
          },
        }}
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle
          className="head4 headerBackground"
          style={{ color: "#EEEEEE", height: "20px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
            }}
          >
            <div style={{ flex: 1 }}>{"Time Zone Selection"}</div>
            <div>
              <CloseIcon
                onClick={handleClose}
                style={{
                  color: "#fffff",
                  height: "30px",
                  width: "30px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            fontFamily: "font_d",
            fontSize: 17,
            padding: "10px",
            paddingLeft: 38,
            paddingRight: 21,
            paddingTop: 24,
          }}
        >
          The date and time of tracking status are according to time-zone of
          concerned locations. If you want to change them as per your home town,
          then please submit your country and city.
          <div>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid xs={12} md={6} item>
                <label
                  className="text1"
                  style={{
                    fontFamily: "font_d",
                    paddingLeft: 14,
                    fontSize: "16px",
                    color: "#666666",
                  }}
                >
                  Country
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </label>
                <AutoCompleteSelect
                  handleSelect={(event, value, name) =>
                    handleSelect(event, value, name, "region")
                  }
                  options={countriesData || []}
                  name="user_country"
                  value={state?.region?.selected_user_country}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <label
                  className="text1"
                  style={{
                    fontFamily: "font_d",
                    paddingLeft: 14,
                    fontSize: "16px",
                    color: "#666666",
                  }}
                >
                  City
                  <span
                    style={{
                      color: "red",
                      fontSize: "15px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </label>
                <AutoCompleteSelect
                  handleSelect={(event, value, name) =>
                    handleSelect(event, value, name, "region")
                  }
                  options={state?.region?.cities || []}
                  name="user_city"
                  value={state?.region?.user_city}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <label
                  className="text1"
                  style={{
                    fontFamily: "font_d",
                    paddingLeft: 14,
                    fontSize: "16px",
                    color: "#666666",
                    marginBottom: 5,
                  }}
                >
                  Timezone
                </label>

                <AutoCompleteSelect
                  handleSelect={(event, value, name) =>
                    handleSelect(event, value, name, "region")
                  }
                  options={timezone || []}
                  name="user_timezone"
                  value={state?.region?.selected_user_timezone}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <Divider />

        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="btntext2"
            onClick={handleClose}
            variant="outlined"
            size="large"
            style={{
              color: "#3344b5",
              outlineColor: "#F8F8F8",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            Cancel
          </Button>
          <Button
            className="btntext1"
            onClick={handleSubmit}
            autoFocus
            variant="contained"
            size="large"
            style={{
              color: "#f8f8f8",
              outlineColor: "#F8F8F8",
              background: "#3344b5",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        style={{ height: "700px" }}
        maxWidth="sm"
        open={state?.open}
        onClose={handleClose}
      >
        <DialogTitle
          style={{ background: "#3344b5", color: "#EEEEEE", height: "20px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
            }}
          >
            <div style={{ flex: 1 }}>About</div>
            <div>
              <CloseIcon
                onClick={handleClose}
                style={{
                  color: "#fffff",
                  height: "30px",
                  width: "30px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "font_d" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", padding: 7 }}
            >
              <span
                style={{ fontSize: "18px", color: "#181818", marginTop: 10 }}
              >
                Order Tracking Status
              </span>
              <span>
                App Version: 0.6.4
                <br />
                Last Update: 02-14-202
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 7,
                fontFamily: "font_d",
              }}
            >
              <span style={{ color: "#666666" }}>HiTech Prime Pvt. Ltd.</span>
              <span>
                info@hitech-prime.com
                <br />
                (021) 38898067
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <Divider />

        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="btntext2"
            onClick={handleClose}
            variant="outlined"
            size="large"
            style={{
              color: "#3344b5",
              outlineColor: "#F8F8F8",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        onClose={handleClose}
        open={state?.openSupportDialog}
        style={{ overflow: "hidden" }}
        PaperProps={{
          style: {
            height: 420,
          },
        }}
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle
          className="head4 headerBackground"
          style={{ color: "#EEEEEE", height: "20px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
            }}
          >
            <div style={{ flex: 1 }}>{"Support"}</div>
            <div>
              <CloseIcon
                onClick={handleClose}
                style={{
                  color: "#fffff",
                  height: "30px",
                  width: "30px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            fontFamily: "font_d",
            fontSize: 17,
            paddingTop: "20px",
            paddingLeft: 24,
            paddingRight: 14,
          }}
        >
          For further inquiry you can contact to your logistic service provider.
        </DialogContent>
        <DialogContent>
          <Grid container>
            {/* <div
      className="card-para"
      style={{
        display: "flex",
        marginBottom: 5,
      }}
    >
      <a
        style={{
          marginTop: -20,
        }}
        className="text1"
      >
        Subscriber Name
{lastTracking?.}
      </a>
    </div> */}

            <Grid xs={12} item>
              <span style={{ lineHeight: 2 }} className="text2">
                Click to call
              </span>
              <div
                className="card-para"
                style={{
                  display: "flex",
                  marginBottom: 5,
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "#3344b5" }}
                  className="text1"
                  href={`tel:${lastTracking?.support_phone_code || " "}${
                    lastTracking?.support_phone
                  }`}
                >
                  {lastTracking?.support_phone_code || "-"}
                  {lastTracking?.support_phone || "-"}
                </a>
              </div>
            </Grid>
            <hr />
            <Grid xs={12} item>
              <span style={{ lineHeight: 2 }} className="text2">
                Click to email
              </span>
              <div
                className="card-para"
                style={{ display: "flex", marginBottom: 5 }}
              >
                <a
                  style={{ textDecoration: "none", color: "#3344b5" }}
                  className="text1"
                  href={`mailto:${lastTracking?.support_email}`}
                >
                  {lastTracking?.support_email || "-"}
                </a>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />

        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            className="btntext2"
            onClick={handleClose}
            variant="outlined"
            size="large"
            style={{
              color: "#3344b5",
              outlineColor: "#F8F8F8",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* <TrackInformationPDF lastTracking={lastTracking} {...props} />
       <TrackInformationPDF />   */}
    </div>
  );
}
