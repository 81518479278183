export const features = {
  contact: "contact",
  tracking: "tracking",
  details: "order-info-contacts",
};

export const colors = {
  color_a: "#0f1436",
  color_b: "#3344b5",
  color_c: "#c2c7e9 ",
  color_d: "#e6e6fa ",
};

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
