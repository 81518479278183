import {
  Button,
  Card,
  Grid,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FrontPageLogo from "./mainLogo.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import splash from "../src/splash.png";

import "./App.css";
import NotFound from "./routes/NotFound";

export default function Application() {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);

  let navigate = useNavigate();

  const handleChange = (event) => {
    // const result = event.target.value.replace(/\D/g, "");
    setValue(event.target.value);
    if (value.length > 12) {
      setShow(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.length < 12) {
      setShow(true);
    } else {
      navigate(`/track/${value}`);
    }
  };
  return (
    <React.Fragment>
      <div className="hide-mobile">
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{ width: "65%" }}
            className={"login_card"}
            elevation={24}
          >
            <Grid
              container
              style={{
                height: "60vh",
                width: "100%",
              }}
            >
              <Hidden mdDown>
                <Grid xs={12} md={7} item style={{ background: "#3344b5" }}>
                  <div
                    className="opening"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div style={{ marginBottom: "2em" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ fill: "#fff", height: "180px", width: "100%" }}
                        viewBox="0 0 297.68 176.32"
                      >
                        <defs></defs>
                        <title>HiTech Logistics</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              class="cls-1"
                              d="M14.68,86,70.81,54l-.67-1.59c-6.57,1.72-13.07,3.9-19.75,5-4.75.79-10,1.18-14.58,0C23.74,54.13,11.92,49.9,0,46.05L.16,44l77.9-20.27-.19-1.36c-9.33,0-18.68.29-28-.13-4.25-.19-9-.83-12.53-2.92C28.16,13.86,19.52,7.49,9.22.51,12.3.23,13.6,0,14.91,0Q87,.65,159.05,1.4c5.65.07,11.49.33,16.89,1.79,14.45,3.88,23.32,20.49,20.76,36.7C194.27,55.27,185.25,65,171.78,71.71c-15.9,8-33,11.14-50.29,13.64-30.78,4.44-61.69,4.93-92.67,2.51C24.7,87.54,20.61,86.79,14.68,86Z"
                            />
                            <path
                              class="cls-1"
                              d="M62.56,176.32c2.1-3.22,3.4-5.59,5-7.68,5.33-6.77,11.39-13.06,16.06-20.25,6.91-10.63,18.59-12.37,28.78-16.71,29-12.34,55.4-28.6,77-51.63C201.15,67.56,211,53.34,210.12,34.8c-.2-4.58-.54-9.41-2.21-13.58s0-6.37,2.77-8.47C230.26-2,252.47-3.35,273.25,8.93c8.86,5.25,16.33,12.85,24.43,19.38l-1,1.81c-3.62.7-7.24,1.38-10.84,2.12-16.1,3.3-27.76,12.62-35.53,26.84-4.22,7.73-7.9,15.77-11.78,23.69-17.7,36.17-47.72,55.76-86,64.79Q121.62,154.87,91.12,164C81.73,166.86,73,171.76,62.56,176.32Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div style={{ marginTop: "-10px", alignItems: "center" }}>
                      <p
                        style={{
                          fontFamily: "font_g",
                          fontSize: "48px",
                          textAlign: "center",
                          lineHeight: "66px",
                        }}
                      >
                        HiTech Logistics
                      </p>
                      <Typography
                        variant="h5"
                        style={{ fontSize: "22px", fontFamily: "font_h" }}
                        component="p"
                      >
                        {`All-in-One Solution for Freight and Transport`}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Hidden>

              <Grid xs={12} sm={12} md={5} item style={{ background: "white" }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px 35px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "2.12rem",
                      textAlign: "center",
                      color: "#3344b5",
                      fontFamily: "font_c",
                    }}
                  >
                    Track Order
                  </p>
                  <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                    <div>
                      <TextField
                        id="standard-basic"
                        onChange={handleChange}
                        value={value}
                        label="Enter Tracking Number"
                        variant="standard"
                        fullWidth
                        error={show}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        marginTop: "40px",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          maxwidth: 150,
                          maxHeight: 145,
                          minWidth: 130,
                          minHeight: 45,
                          fontSize: 16,
                          fontWeight: "none",
                          lineHeight: "normal",
                          letterSpacing: "0.04em",
                          color: "#f8f8f8",
                          background: "#3344b5",
                          border: "1px solid #3344b5",
                        }}
                        type="submit"
                        endIcon={
                          <ArrowForwardIcon
                            style={{ fontSize: `20px !important` }}
                          />
                        }
                      >
                        Continue
                      </Button>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>

      <div
        className="hide-web homepage-bg"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={FrontPageLogo}
            style={{ height: "300px", width: "100px", objectFit: "contain" }}
            alt="pwa-hitech-logistics"
          />
        </div>
        <div
          style={{
            height: "400px",
            background: "white",
            borderRadius: "30px 30px 0px 0px",
          }}
        >
          <p
            style={{
              fontSize: "2.3rem",
              textAlign: "center",
              color: "#3344b5",
              paddingTop: "15px",
              fontFamily: "font_d",
            }}
          >
            Track Order
          </p>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: " 0px 45px",
              paddingBottom: "100px",
              borderRadius: "50px 50px 0px 0px",
              background: "white",
            }}
          >
            <form
              style={{ width: "100%", marginTop: "-140px" }}
              onSubmit={handleSubmit}
            >
              <div>
                <TextField
                  id="standard-basic"
                  onChange={handleChange}
                  value={value}
                  label="Enter Tracking Number"
                  variant="standard"
                  fullWidth
                  error={show}
                  style={{ width: "100%" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                  marginTop: "40px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    maxwidth: 150,
                    maxHeight: 145,
                    minWidth: 130,
                    minHeight: 45,
                    fontSize: 16,
                    fontWeight: "none",
                    lineHeight: "normal",
                    letterSpacing: "0.04em",
                    color: "#3344b5",
                    background: "#f8f8f8",
                    border: "1px solid #3344b5",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                  endIcon={
                    <ArrowForwardIcon style={{ fontSize: `20px !important` }} />
                  }
                >
                  Continue
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
