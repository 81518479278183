import React from "react";
import "../../App.css";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import { width } from "@mui/system";

 const styles = StyleSheet.create({
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  page: {
    paddingHorizontal: 20,
  },
  body: {
   
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 22,
    color: "#181818",
   },
  invoice_title: {
    fontSize: 30,
    color: "#181818",
   },
  address_line: {
    fontSize: 10,
    color: "#666666",
     width: "55%",
  },
  section: {
    flexGrow: 1,
  },
  panel_head: {
    fontSize: 13,
    color: "#181818",
    padding: 5,
    backgroundColor: "#F6F6F6",
     paddingLeft: 10,
  },
  detail_text: {
    margin: "2px 0px",
    fontSize: 11,
    color: "#0F1436",
     width: "75%",
  },
  table_head_cell: {
    fontSize: 11,
    flexGrow: 1,
    padding: 5,
    borderBottom: "1px solid #A4A4A4",
 
    pageBreakBefore: "always",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
     right: 0,
    textAlign: "center",
    color: "grey",
  },
  signature: {
    position: "absolute",
    fontSize: 12,
     textAlign: "center",
    bottom: 30,
    color: "grey",
    right: 20,
  },
});

// Create Document Component
export const TrackInformationPDF = (props) => {
  console.log("PROPE", props);
  return (
    // <PDFViewer style={{ width: "100%", height: "95vh" }}>
  //  {console.log("props", props)}
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text>Order Tracking Status</Text>
            </View>
          </View>

          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "48%",
              }}
            >
              <Text>Order Tracking Number </Text>
            </View>
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "50%",
                marginLeft: "2%",
              }}
            >
              <Text>Shipping ID</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "48%",
                padding: "5px 5px 5px 5px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
              }}
            >
              <Text style={{ ...styles.detail_text }}>
                {props?.lastTracking?.customer_order}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                padding: "5px 5px 5px 5px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
                marginLeft: "2%",
              }}
            >
              <Text style={{ ...styles.detail_text }}>
                {props?.lastTracking?.resource_id}
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "48%",
              }}
            >
              <Text>Origin </Text>
            </View>
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "50%",
                marginLeft: "2%",
              }}
            >
              <Text>Destination </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "48%",
                padding: "0px 0px 0px 10px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
              }}
            >
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
                <Text style={styles.detail_text}>
                  {props?.lastTracking?.c_order_loading_add_city || "-"},
                  {props?.lastTracking?.c_order_loading_add_country || "-"}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ ...styles.detail_text, lineHeight: 2 }}>
                  {!props?.lastTracking?.c_order_etd
                    ? "- - -"
                    : props?.lastTracking?.c_order_etd &&
                      !props?.lastTracking?.c_order_etd_time
                    ? moment(props?.lastTracking?.c_order_etd).format(
                        "DD MMM YYYY"
                      )
                    : props?.lastTracking?.c_order_etd &&
                      props?.lastTracking?.c_order_etd_time
                    ? `${moment(props?.lastTracking?.c_order_etd).format(
                        "DD MMM YYYY"
                      )} ${props?.lastTracking?.c_order_etd_time.substring(
                        0,
                        5
                      )} ${
                        props?.lastTracking?.c_order_etd_tz
                          ? props?.lastTracking?.c_order_etd_tz
                          : "-"
                      }`
                    : "- - -"}
                </Text>
               </View>

              <View style={{ flexDirection: "row" }}>
                <Text style={styles.detail_text}></Text>
                <Text
                  style={{
                    ...styles.detail_text,
                  }}
                ></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.detail_text}> </Text>
                <Text style={styles.detail_text}></Text>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                padding: "0px 0px 0px 10px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
                marginLeft: "2%",
              }}
            >
              <Text style={{ ...styles.detail_text, paddingTop: 5 }}>
                {props?.lastTracking?.c_order_unloading_add_city || "-"},
                {props?.lastTracking?.c_order_unloading_add_country || "-"}
              </Text>
              <Text style={{ ...styles.detail_text }}>
                {!props?.lastTracking?.c_order_eta
                  ? "- - -"
                  : props?.lastTracking?.c_order_eta &&
                    !props?.lastTracking?.c_order_eta_time
                  ? moment(props?.lastTracking?.c_order_eta).format(
                      "DD MMM YYYY"
                    )
                  : props?.lastTracking?.c_order_eta &&
                    props?.lastTracking?.c_order_eta_time
                  ? `${moment(props?.lastTracking?.c_order_eta).format(
                      "DD MMM YYYY"
                    )} ${props?.lastTracking?.c_order_eta_time.substring(
                      0,
                      5
                    )} ${
                      props?.lastTracking?.c_order_eta_tz
                        ? props?.lastTracking?.c_order_eta_tz
                        : "-"
                    }`
                  : "- - -"}
              </Text>
              <Text style={styles.detail_text}></Text>
              <Text style={styles.detail_text}></Text>
              <Text style={styles.detail_text}></Text>
              <Text style={styles.detail_text}></Text>
            </View>
          </View>

          <View style={{ width: "100%", flexDirection: "row", marginTop: 20 }}>
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "100%",
              }}
            >
              <Text>Order Info</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "100%",
                padding: "0px 0px 0px 10px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
              }}
            >
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
                <Text
                  style={{ width: "10%", fontSize: "11px", color: "#666666" }}
                >
                  S.No
                </Text>
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Load Name
                </Text>
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Weight
                </Text>
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Load Item
                </Text>
              </View>

             {props?.lastTracking?.movement_type === "Bilty" ? 
              props?.lastTracking?.resource_booking_ids?.map((i, idx) => (
                <View style={{ flexDirection: "row", paddingTop: 5 }}>
                  <Text
                    style={{
                      width: "10%",
                      display: "flex",
                      fontSize: "11px",
                    }}
                  >
                    {idx + 1}{" "}
                  </Text>
                  <Text style={{ ...styles.detail_text, width: "30%" }}>
                    {i?.load_name || "-"}
                  </Text>
                  <Text style={{ ...styles.detail_text, width: "30%" }}>
                    {i?.weight || "-"} {i?.weight_unit || "-"}
                  </Text>
                  <Text style={{ ...styles.detail_text, width: "30%" }}>
                    {i?.item_name || "-"} {i?.item_unit || "-"}
                  </Text>
                </View>
              ))
            : props?.lastTracking?.customer_loads_info?.map((i, idx) => (
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
                <Text
                  style={{
                    width: "10%",
                    display: "flex",
                    fontSize: "11px",
                  }}
                >
                  {idx + 1}{" "}
                </Text>
                <Text style={{ ...styles.detail_text, width: "30%" }}>
                  {i?.load_name || "-"}
                </Text>
                <Text style={{ ...styles.detail_text, width: "30%" }}>
                  {i?.weight || "-"} {i?.weight_unit || "-"}
                </Text>
                <Text style={{ ...styles.detail_text, width: "30%" }}>
                  {i?.item_name || "-"} {i?.item_unit || "-"}
                </Text>
              </View>
            ))}
             
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.detail_text}> </Text>
                <Text style={styles.detail_text}></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.detail_text}> </Text>
                <Text
                  style={{
                    ...styles.detail_text,
                  }}
                ></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.detail_text}></Text>
                <Text style={styles.detail_text}></Text>
              </View>
            </View>
          </View>

          <View style={{ width: "100%", flexDirection: "row", marginTop: 20 }}>
            <View
              style={{
                ...styles.panel_head,
                border: "1px solid #A4A4A4",
                width: "100%",
              }}
            >
              <Text>Order Status</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "100%",
                padding: "0px 0px 0px 10px",
                borderRight: "1px solid #A4A4A4",
                borderBottom: "1px solid #A4A4A4",
                borderLeft: "1px solid #A4A4A4",
              }}
            >
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
               
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Status
                </Text>
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Date
                </Text>
                <Text
                  style={{ width: "30%", fontSize: "11px", color: "#666666" }}
                >
                  Time
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingTop: 5,paddingBottom:5 }}>
               
                <Text style={{ ...styles.detail_text,width: "30%", fontSize: "12px",color:'#3344b5' }}>
                  {props?.lastTracking?.tracking_status === "Expected Start" ? "Schedule Start" : props?.lastTracking?.tracking_status}
                </Text>
                <Text style={{...styles.detail_text, width: "30%", fontSize: "11px" }}>
                  {moment(props?.lastTracking?.tracking_date).format("DD MMM YYYY") ||
                    "-"}{" "}
                </Text>
                <Text style={{ ...styles.detail_text,width: "30%", fontSize: "11px" }}>
                  {props?.lastTracking?.tracking_time ? moment(`2023-01-01T${props?.lastTracking?.tracking_time}`).format(
                    "HH:mm"
                  ) : "- - -"} 
                </Text>
              </View>
            </View>
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} of ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    // </PDFViewer>
  );
};
